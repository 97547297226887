var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%" } },
    [
      _c(
        "el-container",
        { staticClass: "container" },
        [
          !_vm.isShowContent
            ? _c(
                "el-aside",
                {
                  staticClass: "select-area",
                  attrs: { width: "300px", theme: "light" },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-checkbox-button",
                        {
                          staticClass: "tag",
                          model: {
                            value: _vm.tag.checked0,
                            callback: function ($$v) {
                              _vm.$set(_vm.tag, "checked0", $$v)
                            },
                            expression: "tag.checked0",
                          },
                        },
                        [_vm._v("工具")]
                      ),
                      _vm.tag.checked0
                        ? _c(
                            "div",
                            { attrs: { align: "center" } },
                            [
                              _c(
                                "el-button-group",
                                _vm._l(_vm.field.tools, function (tool, index) {
                                  return _c("el-button", {
                                    key: index,
                                    staticStyle: {
                                      "font-size": "16px",
                                      width: "100px",
                                    },
                                    attrs: {
                                      size: "mini",
                                      icon: tool.icon,
                                      type:
                                        _vm.currentTool.type == tool.type
                                          ? "primary"
                                          : "default",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.selectTool(tool.type)
                                      },
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-checkbox-button",
                        {
                          staticClass: "tag",
                          model: {
                            value: _vm.tag.checked1,
                            callback: function ($$v) {
                              _vm.$set(_vm.tag, "checked1", $$v)
                            },
                            expression: "tag.checked1",
                          },
                        },
                        [_vm._v("基础节点")]
                      ),
                      _vm.tag.checked1
                        ? _c(
                            "div",
                            { attrs: { align: "center" } },
                            [
                              _c(
                                "draggable",
                                _vm._b(
                                  {
                                    attrs: {
                                      tag: "el-row",
                                      list: _vm.field.commonNodes,
                                      move: _vm.handleMove,
                                    },
                                    on: {
                                      end: _vm.handleMoveEnd,
                                      start: _vm.handleMoveStart,
                                    },
                                  },
                                  "draggable",
                                  {
                                    group: {
                                      name: "flow",
                                      pull: "clone",
                                      put: false,
                                    },
                                    sort: false,
                                  },
                                  false
                                ),
                                [
                                  _vm._l(
                                    _vm.field.commonNodes,
                                    function (commonNode, index) {
                                      return [
                                        _c(
                                          "el-col",
                                          { key: index, attrs: { span: 12 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "node-item",
                                                attrs: {
                                                  type: commonNode.type,
                                                  belongto: "commonNodes",
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  class: commonNode.icon,
                                                  staticStyle: {
                                                    "font-size": "16px",
                                                  },
                                                }),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(commonNode.name) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-checkbox-button",
                        {
                          staticClass: "tag",
                          model: {
                            value: _vm.tag.checked3,
                            callback: function ($$v) {
                              _vm.$set(_vm.tag, "checked3", $$v)
                            },
                            expression: "tag.checked3",
                          },
                        },
                        [_vm._v("泳道节点")]
                      ),
                      _c(
                        "div",
                        { attrs: { align: "center" } },
                        [
                          _vm.tag.checked3
                            ? _c(
                                "draggable",
                                _vm._b(
                                  {
                                    attrs: {
                                      tag: "el-row",
                                      list: _vm.field.laneNodes,
                                      grid: { gutter: 8, column: 2 },
                                      move: _vm.handleMove,
                                    },
                                    on: {
                                      end: _vm.handleMoveEnd,
                                      start: _vm.handleMoveStart,
                                    },
                                  },
                                  "draggable",
                                  {
                                    group: {
                                      name: "flow",
                                      pull: "clone",
                                      put: false,
                                    },
                                    sort: false,
                                  },
                                  false
                                ),
                                _vm._l(
                                  _vm.field.laneNodes,
                                  function (laneNode, index) {
                                    return _c(
                                      "el-col",
                                      { key: index, attrs: { span: 12 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "node-item",
                                            attrs: {
                                              type: laneNode.type,
                                              belongto: "laneNodes",
                                            },
                                          },
                                          [
                                            _c("i", {
                                              class: laneNode.icon,
                                              staticStyle: {
                                                "font-size": "16px",
                                              },
                                            }),
                                            _vm._v(
                                              " " + _vm._s(laneNode.name) + " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-main",
            { staticStyle: { padding: "0" } },
            [
              _c(
                "el-container",
                { staticStyle: { height: "100%" } },
                [
                  !_vm.isShowContent
                    ? _c(
                        "el-header",
                        {
                          staticClass: "header-option",
                          staticStyle: { height: "auto" },
                        },
                        [
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                title: "确认要重新绘制吗？",
                                placement: "bottom",
                              },
                              model: {
                                value: _vm.isShowPopover,
                                callback: function ($$v) {
                                  _vm.isShowPopover = $$v
                                },
                                expression: "isShowPopover",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "small" },
                                      on: {
                                        click: function ($event) {
                                          _vm.isShowPopover = false
                                        },
                                      },
                                    },
                                    [_vm._v("取消")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary", size: "small" },
                                      on: { click: _vm.clear },
                                    },
                                    [_vm._v("确认")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    slot: "reference",
                                    content: "重新绘制",
                                    placement: "bottom",
                                  },
                                  slot: "reference",
                                },
                                [
                                  _c("el-button", {
                                    staticClass: "header-option-button",
                                    attrs: {
                                      size: "small",
                                      icon: "el-icon-refresh-left",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-main",
                    { staticClass: "content", staticStyle: { padding: "0" } },
                    [
                      _vm.loadFlowArea && _vm.flowData
                        ? _c("flow-area", {
                            ref: "flowArea",
                            attrs: {
                              browserType: _vm.browserType,
                              flowData: _vm.flowData,
                              select: _vm.currentSelect,
                              selectGroup: _vm.currentSelectGroup,
                              plumb: _vm.plumb,
                              isShowContent: _vm.isShowContent,
                              currentTool: _vm.currentTool,
                              isDrag: _vm.isDrag,
                            },
                            on: {
                              "update:select": function ($event) {
                                _vm.currentSelect = $event
                              },
                              "update:selectGroup": function ($event) {
                                _vm.currentSelectGroup = $event
                              },
                              "update:select-group": function ($event) {
                                _vm.currentSelectGroup = $event
                              },
                              findNodeConfig: _vm.findNodeConfig,
                              selectTool: _vm.selectTool,
                              getShortcut: _vm.getShortcut,
                              saveFlow: _vm.saveFlow,
                            },
                          })
                        : _vm._e(),
                      _c("vue-context-menu", {
                        attrs: { contextMenuData: _vm.linkContextMenuData },
                        on: { deleteLink: _vm.deleteLink },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.isShowContent
            ? _c(
                "el-aside",
                {
                  staticClass: "attr-area",
                  attrs: { width: "350px", theme: "light" },
                  on: {
                    mousedown: function ($event) {
                      $event.stopPropagation()
                      return _vm.loseShortcut.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("flow-attr", {
                    attrs: {
                      plumb: _vm.plumb,
                      flowData: _vm.flowData,
                      formTemplate: _vm.formTemplate,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "流程设计图_" + _vm.flowData.attr.id + ".png",
            centered: "",
            width: "90%",
            closable: _vm.flowPicture.closable,
            maskClosable: _vm.flowPicture.maskClosable,
            visible: _vm.flowPicture.modalVisible,
            okText: "下载到本地",
            cancelText: "取消",
          },
          on: {
            ok: _vm.downLoadFlowPicture,
            cancel: _vm.cancelDownLoadFlowPicture,
          },
        },
        [
          _c("div", { attrs: { align: "center" } }, [
            _c("img", { attrs: { src: _vm.flowPicture.url } }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }