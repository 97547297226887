var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-input", {
        attrs: { readonly: true, placeholder: _vm.placeholder },
        nativeOn: {
          click: function ($event) {
            _vm.selectDialog = true
          },
        },
        model: {
          value: _vm.names,
          callback: function ($$v) {
            _vm.names = $$v
          },
          expression: "names",
        },
      }),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-mini custom-dialog user-dialog",
          attrs: {
            "destroy-on-close": true,
            width: "850px",
            title: "选择用户",
            visible: _vm.selectDialog,
          },
          on: {
            "update:visible": function ($event) {
              _vm.selectDialog = $event
            },
          },
        },
        [
          _vm.selectDialog
            ? _c("selectUsersCom", {
                ref: "selectUser",
                attrs: {
                  hiddenFooter: true,
                  orgId: _vm.orgId,
                  show: _vm.selectDialog,
                  "ignore-auth": _vm.ignoreAuth,
                  users: _vm.selectUsers,
                  loginKey: "loginUser",
                  userNames: _vm.names,
                },
                on: {
                  "update:show": function ($event) {
                    _vm.selectDialog = $event
                  },
                  "update:users": function ($event) {
                    _vm.selectUsers = $event
                  },
                  "update:userNames": function ($event) {
                    _vm.names = $event
                  },
                  "update:user-names": function ($event) {
                    _vm.names = $event
                  },
                },
              })
            : _vm._e(),
          _c(
            "div",
            {
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "cancel" },
                  on: {
                    click: function ($event) {
                      _vm.selectDialog = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleSaveUsers },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }