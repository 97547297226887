var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flow-attr-box", staticStyle: { height: "100%" } },
    [
      _c(
        "el-tabs",
        {
          staticStyle: { height: "100%" },
          attrs: { size: "small", value: _vm.activeKey },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { name: "flow-attr", disabled: "" } },
            [
              _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                _c("i", { staticClass: "el-icon-s-operation" }),
                _vm._v(" 流程属性 "),
              ]),
              _c(
                "el-form",
                { attrs: { layout: "horizontal" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        size: "small",
                        label: "流程id",
                        "label-col": _vm.formItemLayout.labelCol,
                        "wrapper-col": _vm.formItemLayout.wrapperCol,
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { value: _vm.flowData.attr.id, disabled: "" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { name: "node-attr", disabled: "" } },
            [
              _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                _c("i", { staticClass: "el-icon-notebook-2" }),
                _vm._v(" 节点属性 "),
              ]),
              _vm.currentSelect && _vm.currentSelect.id
                ? [
                    _c(
                      "el-form",
                      {
                        ref: "dataForm",
                        attrs: { rules: _vm.rules, model: _vm.currentSelect },
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { size: "small", label: "Id", prop: "id" } },
                          [
                            _c("el-input", {
                              attrs: { readonly: "", disabled: "" },
                              model: {
                                value: _vm.currentSelect.id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.currentSelect, "id", $$v)
                                },
                                expression: "currentSelect.id",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              size: "small",
                              label: "名称",
                              prop: "name",
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.currentSelect.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.currentSelect, "name", $$v)
                                },
                                expression: "currentSelect.name",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.currentSelect.setInfo
                          ? [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    size: "small",
                                    label: "三方回调URL",
                                    prop: "ThirdPartyUrl",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value:
                                        _vm.currentSelect.setInfo.ThirdPartyUrl,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.currentSelect.setInfo,
                                          "ThirdPartyUrl",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "currentSelect.setInfo.ThirdPartyUrl",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    size: "small",
                                    label: "执行权限",
                                    prop: "NodeDesignate",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "filter-item",
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: "请选择",
                                        "popper-append-to-body": false,
                                      },
                                      on: { change: _vm.handleChangeRoles },
                                      model: {
                                        value:
                                          _vm.currentSelect.setInfo
                                            .NodeDesignate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.currentSelect.setInfo,
                                            "NodeDesignate",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "currentSelect.setInfo.NodeDesignate",
                                      },
                                    },
                                    _vm._l(_vm.NodeDesignates, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    size: "small",
                                    label: "可编辑的表单字段",
                                    prop: "CanWriteFormItemIds",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "filter-item",
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        multiple: "",
                                        placeholder: "请选择",
                                        "popper-append-to-body": false,
                                      },
                                      model: {
                                        value:
                                          _vm.currentSelect.setInfo
                                            .CanWriteFormItemIds,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.currentSelect.setInfo,
                                            "CanWriteFormItemIds",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "currentSelect.setInfo.CanWriteFormItemIds",
                                      },
                                    },
                                    _vm._l(_vm.formTemplate, function (item) {
                                      return _c("el-option", {
                                        key: item.name,
                                        attrs: {
                                          label: item.title,
                                          value: item.name,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.currentSelect.setInfo.NodeDesignate ===
                              "SPECIAL_USER"
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        size: "small",
                                        label: "指定用户",
                                        prop: "NodeDesignateUsers",
                                      },
                                    },
                                    [
                                      _c("select-users", {
                                        attrs: {
                                          userNames:
                                            _vm.currentSelect.setInfo
                                              .NodeDesignateData.Texts,
                                          users:
                                            _vm.currentSelect.setInfo
                                              .NodeDesignateData.users,
                                          "ignore-auth": true,
                                        },
                                        on: {
                                          "update:userNames": function (
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.currentSelect.setInfo
                                                .NodeDesignateData,
                                              "Texts",
                                              $event
                                            )
                                          },
                                          "update:user-names": function (
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.currentSelect.setInfo
                                                .NodeDesignateData,
                                              "Texts",
                                              $event
                                            )
                                          },
                                          "users-change": _vm.usersChange,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.currentSelect.setInfo.NodeDesignate ===
                              "SPECIAL_ROLE"
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        size: "small",
                                        label: "指定角色",
                                        prop: "NodeDesignateUsers",
                                      },
                                    },
                                    [
                                      _c("select-roles", {
                                        attrs: {
                                          userNames:
                                            _vm.currentSelect.setInfo
                                              .NodeDesignateData.Texts,
                                          roles:
                                            _vm.currentSelect.setInfo
                                              .NodeDesignateData.roles,
                                          "ignore-auth": true,
                                        },
                                        on: {
                                          "update:userNames": function (
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.currentSelect.setInfo
                                                .NodeDesignateData,
                                              "Texts",
                                              $event
                                            )
                                          },
                                          "update:user-names": function (
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.currentSelect.setInfo
                                                .NodeDesignateData,
                                              "Texts",
                                              $event
                                            )
                                          },
                                          "roles-change": _vm.rolesChange,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.currentSelect.type == "fork"
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        size: "small",
                                        label: "会签类型",
                                        prop: "NodeConfluenceType",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "filter-item",
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            placeholder: "请选择",
                                            "popper-append-to-body": false,
                                          },
                                          model: {
                                            value:
                                              _vm.currentSelect.setInfo
                                                .NodeConfluenceType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.currentSelect.setInfo,
                                                "NodeConfluenceType",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "currentSelect.setInfo.NodeConfluenceType",
                                          },
                                        },
                                        _vm._l(
                                          _vm.NodeConfluenceTypes,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.id,
                                              attrs: {
                                                label: item.name,
                                                value: item.id,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c(
            "el-tab-pane",
            { attrs: { name: "link-attr", disabled: "" } },
            [
              _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                _c("i", { staticClass: "el-icon-share" }),
                _vm._v(" 连线属性 "),
              ]),
              _c(
                "el-form",
                { attrs: { "label-position": "top" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        size: "small",
                        label: "id",
                        "label-col": _vm.formItemLayout.labelCol,
                        "wrapper-col": _vm.formItemLayout.wrapperCol,
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { value: _vm.currentSelect.id, disabled: "" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        size: "small",
                        label: "源节点",
                        "label-col": _vm.formItemLayout.labelCol,
                        "wrapper-col": _vm.formItemLayout.wrapperCol,
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { value: _vm.currentSelect.from, disabled: "" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        size: "small",
                        label: "目标节点",
                        "label-col": _vm.formItemLayout.labelCol,
                        "wrapper-col": _vm.formItemLayout.wrapperCol,
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { value: _vm.currentSelect.to, disabled: "" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        size: "small",
                        label: "文本",
                        "label-col": _vm.formItemLayout.labelCol,
                        "wrapper-col": _vm.formItemLayout.wrapperCol,
                      },
                    },
                    [
                      _c("el-input", {
                        on: { change: _vm.linkLabelChange },
                        model: {
                          value: _vm.currentSelect.label,
                          callback: function ($$v) {
                            _vm.$set(_vm.currentSelect, "label", $$v)
                          },
                          expression: "currentSelect.label",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { display: "inline-block" },
                      attrs: {
                        size: "small",
                        "label-col": _vm.formItemLayout.labelCol,
                        "wrapper-col": _vm.formItemLayout.wrapperCol,
                        label: "表单字段条件",
                      },
                    },
                    _vm._l(_vm.Compares, function (compare, index) {
                      return _c(
                        "div",
                        { key: index, staticStyle: { "margin-bottom": "5px" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "85px" },
                              attrs: {
                                "popper-append-to-body": false,
                                placeholder: "请选择",
                              },
                              model: {
                                value: compare.FieldName,
                                callback: function ($$v) {
                                  _vm.$set(compare, "FieldName", $$v)
                                },
                                expression: "compare.FieldName",
                              },
                            },
                            _vm._l(_vm.formTemplate, function (item) {
                              return _c("el-option", {
                                key: item.name,
                                attrs: { label: item.title, value: item.name },
                              })
                            }),
                            1
                          ),
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "85px" },
                              attrs: {
                                "popper-append-to-body": false,
                                disabled: !compare.FieldName,
                                placeholder: "请选择",
                              },
                              model: {
                                value: compare.Operation,
                                callback: function ($$v) {
                                  _vm.$set(compare, "Operation", $$v)
                                },
                                expression: "compare.Operation",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: ">", value: ">" },
                              }),
                              _c("el-option", {
                                attrs: { label: ">=", value: ">=" },
                              }),
                              _c("el-option", {
                                attrs: { label: "<", value: "<" },
                              }),
                              _c("el-option", {
                                attrs: { label: "<=", value: "<=" },
                              }),
                              _c("el-option", {
                                attrs: { label: "=", value: "=" },
                              }),
                              _c("el-option", {
                                attrs: { label: "!=", value: "!=" },
                              }),
                            ],
                            1
                          ),
                          _c("el-input", {
                            staticStyle: { width: "80px" },
                            attrs: {
                              disabled: !compare.FieldName,
                              placeholder: "值",
                            },
                            model: {
                              value: compare.Value,
                              callback: function ($$v) {
                                _vm.$set(compare, "Value", $$v)
                              },
                              expression: "compare.Value",
                            },
                          }),
                          index === 0
                            ? _c("el-button", {
                                attrs: {
                                  type: "primary",
                                  size: "mini",
                                  icon: "el-icon-plus",
                                  title: "并且",
                                },
                                on: { click: _vm.btnAddCompare },
                              })
                            : _vm._e(),
                          index != 0
                            ? _c("el-button", {
                                attrs: {
                                  type: "danger",
                                  size: "mini",
                                  icon: "el-icon-delete",
                                  title: "删除",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.btnDelCompare(index)
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }